// 根据 博客分类id 获取首页 其他博客 的数据
 import { getotherblogbyid } from '@/api'
import Vue from 'vue'
// 引入vuex
import Vuex from 'vuex'

Vue.use(Vuex)

const actions = {
    // 将账号密码传给mutations
    
    async getotherblog({ commit },params) {
        const result = await getotherblogbyid (params)
    
       

        if (result.code == 200) {
            console.log("首页数据接收成功",result);
            commit("Getotherblog", result)
            // console.log(result.data,"这是仓库上的数据*****");
            // console.log('---------------------------');
        }

    },

    
}
const mutations = {
    Getotherblog(state,value){
        state.otherblog= value 
    
     
    },
 
}
const state = {

    otherblog:[]
   
}

export default {
    // namespaced: true,  //关闭
    actions,
    mutations,
    state,

 
}