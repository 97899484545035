import vue from 'vue'
import VueRouter from 'vue-router'
vue.use(VueRouter)
import routes from './routes'
export default new VueRouter({
    routes,
    //滚动行为
    scrollBehavior (to, from, savedPosition) {
        // y代表滚动条在上方
        return {y:0}
      },
  
      

})
