// 当前模块统一管理apirequests
import requests from "./request";

// import mockWeb from '@/api/mockWeb'

// // 三级联动的接口
// eslint-disable-next-line no-undef
// export const reqCatgoryList = ()=> requests({url:'/product/getBaseCategoryList',method:'get'});
// 获取首页轮播图接口

// export const reqGetWebs = ()=> {
//     return mockWeb({url:'/collection',method:'get'})
// }




// 网站接口 ：39.105.55.190
// 本地接口 ：127.0.0.1
// 获取笔记 javascript 的接口：

// export const reqTests =(params)=>requests({url:'http://127.0.0.1/api/getjavascript',method:'post',data:params})

// export const reqTest = (params)=>{
//     return requests({url:'http://127.0.0.1/api/getjavascript',method:'post',data:params})
// }
// 获取分页的接口
export const reqTest2 = (params)=>{
    return requests({url:'http://124.222.140.178/api/getpagescount',method:'post',data:params})
}
// 根据点击博客的id获取对应js_text的接口

export const getblogtextbyid = (params) =>{
    return requests({url:'http://124.222.140.178/api/getblogtextbyid',method:'post',data:params})
}
// export const getblogtextbyid = (params) =>{
//     return requests({url:'http://39.105.55.190/api/getblogtextbyid',method:'post',data:params})
// }
// 根据博客分类id和分页获取数据的接口
export const getblogbycategoryid = (params) =>{
    return requests({url:'http://124.222.140.178/api/getblogcategorybyid',method:'post',data:params})
}
// 根据博客文章id增加访问量的接口
export const upblogviewsbyid = (params) =>{
    return requests({url:'http://124.222.140.178/api/upblogviewsbyid',method:'post',data:params})
}
// 根据博客文章id增加点赞的接口
export const upbloglikebyid = (params) =>{
    return requests({url:'http://124.222.140.178/api/upbloglikebyid',method:'post',data:params})
}
// 根据博客id添加评论的接口
export const addcommentbyid = (params) =>{
    return requests({url:'http://124.222.140.178/api/addcommentbyid',method:'post',data:params})
}
// 根据博客id 分页 获取分页评论的数据
export const getcommentbyid = (params) =>{
    return requests({url:'http://124.222.140.178/api/getcommentbyid',method:'post',data:params})
}
// 根据博客id获取 其他分类博客 数据的接口
export const getotherblogbyid = (params) =>{
    return requests({url:'http://124.222.140.178/api/getotherblogbyid',method:'post',data:params})
}

