import { upblogviewsbyid } from '@/api'
// 用于博客分类传参的仓库 参数为 category_id
// 用于增加博客访问量和点赞的仓库
// 当 note路由页面点击之后 需要在仓库中修改代表 notetype页面是哪个分类的 num
import Vue from 'vue'
// 引入vuex
import Vuex from 'vuex'

Vue.use(Vuex)

const actions = {

    passcategoryid({ commit }, categoryid) {
        console.log(categoryid, "这是categoryid");
        commit("Passcategoryid", categoryid)


    },
    // 增加访问量
    async upviews(params) {
      
        const result = await upblogviewsbyid(params)
        console.log(params,"这是params");
        console.log("触发了actions");

        if (result.code == 200) {
            console.log("访问量增加成功");
        }

    },
}
const mutations = {
    Passcategoryid(state, value) {
        state.blogcategoryid = value


    },

}
const state = {

    blogcategoryid: ''
}

export default {
    // namespaced: true,  //关闭
    actions,
    mutations,
    state,


}