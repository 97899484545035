// 该文件用于创建Vuex中最为核心的store
import { reqGetWebVue } from '@/api'
import Vue from 'vue'
// 引入vuex
import Vuex from 'vuex'

Vue.use(Vuex)
// 该文件为登录注册信息的vuex
const actions = {
    // 将账号密码传给mutations
    async getvuewebsites({ commit }) {
        const result = await reqGetWebVue()



        if (result.code == 200) {

            commit("Getvuewebsites", result.data)
        }

    },


}
const mutations = {
    Getvuewebsites(state, value) {
        state.WebVueImgs = value

    },

}
const state = {
    WebVueImgs: []

}

export default {
    // namespaced: true,  //关闭
    actions,
    mutations,
    state,

}