<template>
  <div>
    <div class="floor">
      <div class="pull-left">"Copyright 2023" @Muzy `Blogs</div>
      <div class="pull-right">
        <img src="./images/beian.d0289dc.png" alt="" />
        <a href="https://www.beian.gov.cn/" target="_blank">
          蜀ICP备2023002142号</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name:'FloorS'
}
</script>

<style scoped>
    .floor {
  display: flex;
  writing-mode: horizontal-tb;
  justify-content: space-between; /* 两个子元素分别排列在容器两侧 */
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #4a4f4feb;

}
.pull-left {
  color: #bdbdbd;
  writing-mode: horizontal-tb;
  margin-left: 200px;

  /* margin-top: 200px; */
}
.pull-right {
  display: flex;
  justify-items: center;
  align-items: center;
    margin-right: 10%;
  color: #bdbdbd;
    /* margin-left: 0; */
}
.pull-right img {
  margin-right: 10px;
  /* margin-top: 10px; */
}
.pull-right a {
  color: #bdbdbd;
}
@media screen and (max-width:700px) {

  .floor {
    height: 40px;
    background-color: #4a4f4feb;
  }
  .pull-left {
    float: left;
    margin-left: 20px;
    font-size: x-small;
  }
  .pull-right {
    float: right;
    margin-left: 0px;
    font-size: x-small;
    margin-right: 10px;
  }
}
</style>