// import mainpage from '@/pages/WebMainPage'
import website from '@/pages/Website'

// import note from '@/pages/Note'
// import moreblogtext from '@/pages/moreblogtext'
import notetype from '@/pages/NoteType'
export default [
    {
        name:'more',
        path:'/more',
        component:()=> import ('@/pages/More')
    },
    {
        name:'websitelike',
        path:'/websitelike',
        component:() => import ('@/pages/WebsiteLike')
    },
    {
        name: 'moreblogtext',
        path: '/moreblogtext/:id?',
        component: ()=> import ('@/pages/moreblogtext')
    },
    {
        name: 'mainpage',
        path: '/mainpage',
        component: ()=> import ('@/pages/WebMainPage')
    },
    {
        name: 'note',
        path: '/note',
        component: ()=> import('@/pages/Note')
    },
    {
        name:'notetype',
        path:'/notetype/:id?',
        component:notetype
    },
    {
        name: 'website',
        path: '/website',
        component: website,

        children: [


       
        ],
        redirect: '/websitecss'
    },



    // 重定向路由，在项目跑起来的时候，访问到/，立马让它定向到首页
    {
        path: '/',
        // component: mainpage,
        redirect: '/mainpage'
    }
]