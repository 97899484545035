import { addcommentbyid } from '@/api'
import { getcommentbyid } from '@/api'
// 用于存放属于该博客id的评论 and 和发送请求拿到属于博客id的评论
import Vue from 'vue'
// 引入vuex
import Vuex from 'vuex'

Vue.use(Vuex)

const actions = {

    async addcomment({ commit }, params) {
        // 添加评论
      
        const result = await addcommentbyid(params)
     

    },
    //获取评论分页数据
    async getcomment({ commit }, params) {
        console.log(params, "这是params");
        const result = await getcommentbyid(params)
   
        if (result.code == 200) {
            commit('Getcomment', result)
            // commit('Getcomment',result)
            console.log("获取分页数据成功",result );
        }
    }

}
const mutations = {
    Getcomment(state, value) {
        state.blogcomment = value.data
        state.pagecount = value.totalPages
         

    },

}
const state = {

    blogcomment: {},
    pagecount:''
}

export default {
    // namespaced: true,  //关闭
    actions,
    mutations,
    state,


}