// 该文件用于创建Vuex中最为核心的store
import { reqGetWebUi } from '@/api'
import Vue from 'vue'
// 引入vuex
import Vuex from 'vuex'

Vue.use(Vuex)

const actions = {
    // 将账号密码传给mutations
    async getwebui({ commit }) {
        const result = await  reqGetWebUi()
        if (result.code == 200) {
            console.log(result.data);
            commit("Getwebui", result.data)
        }

    },

    
}
const mutations = {
    Getwebui(state,value){
        state.WebUis= value
     
    },
 
}
const state = {
    WebUis:[]
   
}

export default {
    // namespaced: true,  //关闭
    actions,
    mutations,
    state,
 
}