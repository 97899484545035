<template>
  <transition
    appear=""
    name="animate__animated animate__bounce"
    enter-active-class="animate__fadeIn"
  >
    <div class="website">
      <div class="left">
        <ul class="left-ul">
          <li @click="gocss()"><p>CSS样式</p></li>
          <li @click="gojs()"><p>Javascript</p></li>
          <li @click="govue()"><p>Vue2</p></li>
          <li @click="goui()"><p>ui库</p></li>
          <li @click="govgimg()"><p>矢量图</p></li>
          <li><p>其他</p></li>
        </ul>
      </div>

      <div class="line"></div>

      <router-view></router-view>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "WebSite",
  mounted() {
    // 发请求拿到网站的数据

    this.$store.dispatch("getcsswebsites");
  
  },
  computed: {
    ...mapState({
      csswebsites: (state) => state.CssWebSite.CssWebSites,
    }),
  },

  methods: {
    govgimg() {
      this.$router.push({ name: "websitevgimg" });
    },
    gocss() {
      this.$router.push({ name: "websitecss" });
    },
    goui() {
      this.$router.push({ name: "websiteui" });
    },
    gojs() {
      this.$router.push({ name: "websitejs" });
    },
    govue() {
      this.$router.push({ name: "websitevue" });
    },
  },
};
</script>

<style scoped>
.website {
  width: 1200px;
  /* height: 1000px; */
  /* background-color: #faebd7; */
  margin: 0 auto;
  padding-top: 150px;
  /* background-image: linear-gradient(to top, hsl(0, 90%, 92%) 0%, #effafd 100%); */
 
}
.left-ul {
  /* width: 400px;
    height: 500px;
    /* background-color: aquamarine; */

  position: fixed;
  margin-left: 50px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04); */
}
.left-ul li {
  width: 300px;
  height: 60px;
  /* background-image: linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%); */
  margin-bottom: 30px;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  background-image: linear-gradient(120deg, #fdfbfb 0%, #d5e7f0 100%);
  line-height: 50px;
}
.left-ul li:hover {
  /* border-bottom: 2px solid red; */
}
.left-ul li p {
  font-size: larger;
  font-weight: 550;
  margin-left: 100px;
}
.line {
  position: fixed;
  margin-left: 400px;
  height: 400px;
  width: 1px;
  border-right: 2px solid rgba(40, 151, 236, 0.497);
  opacity: 0.5;
  box-shadow: 0 2px 4px rgba(220, 10, 10, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
.left-ul li {
  transition: all 0.4s;
  cursor: pointer;
}
.left-ul li:hover {
  transform: scale(1.05);
  color: rgb(0, 183, 255);
}
/*  */
/*  */
.ul {
  /* display: flex; */
  display: inline-block;

}
.ul li {
  /* position: relative; */
  /* display: inline-block; */
  float: left;

  margin-left: 55px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.right {
  left: 560px;
  overflow: hidden;
  position: absolute;
  width: 900px;
  /* height: 800px; */
  /* background-color: #ba1d1d; */
}
.urlimg {
  border-radius: 50%;
}
/*  */

.card {
  width: 170px;
  height: 234px;
  background: #f5f5f5;
  padding: 2rem 1.5rem;
  -webkit-transition: -webkit-box-shadow 0.3s ease, -webkit-transform 0.2s ease;
  transition: -webkit-box-shadow 0.3s ease, -webkit-transform 0.2s ease;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
  transition: box-shadow 0.3s ease, transform 0.2s ease,
    -webkit-box-shadow 0.3s ease, -webkit-transform 0.2s ease;
}

.card-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
  transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, opacity 0.2s ease;
  transition: transform 0.2s ease, opacity 0.2s ease,
    -webkit-transform 0.2s ease;
}

/*Image*/
.card-avatar {
  --size: 60px;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#f1e1c1),
    to(#fcbc97)
  );
  background: linear-gradient(to top, #f1e1c1 0%, #fcbc97 100%);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  margin-bottom: 1rem;
}

/*Card footer*/
.card-social {
  -webkit-transform: translateY(200%);
  -ms-transform: translateY(200%);
  transform: translateY(200%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  width: 100%;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
  transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, opacity 0.2s ease;
  transition: transform 0.2s ease, opacity 0.2s ease,
    -webkit-transform 0.2s ease;
}

.card-social__item {
  list-style: none;
}

.card-social__item svg {
  display: block;
  height: 18px;
  width: 18px;
  fill: #515f65;
  cursor: pointer;
  -webkit-transition: fill 0.2s ease, -webkit-transform 0.2s ease;
  transition: fill 0.2s ease, -webkit-transform 0.2s ease;
  transition: fill 0.2s ease, transform 0.2s ease;
  transition: fill 0.2s ease, transform 0.2s ease, -webkit-transform 0.2s ease;
}

/*Text*/
.card-title {
  color: #333;
  font-size: 1.5em;
  font-weight: 600;
  line-height: 2rem;
}

.card-subtitle {
  color: #859ba8;
  font-size: 0.8em;
}

/*Hover*/
.card:hover {
  -webkit-box-shadow: 0 8px 50px #04ffd133;
  box-shadow: 0 8px 50px #0274ff33;
}

.card:hover .card-info {
  -webkit-transform: translateY(-5%);
  -ms-transform: translateY(-5%);
  transform: translateY(-5%);
}

.card:hover .card-social {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  opacity: 1;
}

.card-social__item svg:hover {
  fill: #232323;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.card-avatar:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
/*  */
.botton {
  width: 200px;
  height: 140px;
  margin-top: 10px;
  /* background-color: #b71414; */
}
.moreinfo {
  margin-top: 20px;
  width: 170px;
  height: 55px;
  /* background-color: aqua; */
  margin-left: 15px;
  text-indent: 2em;
}

/*  */
/* From uiverse.io by @alshahwan */
/* From uiverse.io by @alshahwan */
button {
  background-color: #fff;
  border: 1px solid #0077b5;
  padding: 5px;
  position: relative;
  width: 7.2em;
  height: 2em;
  transition: 0.5s;
  font-size: 17px;
  border-radius: 0.4em;
}

button p {
  position: absolute;
  top: 0.4em;
  left: 1.2em;
  margin: 0;
  padding: 0;
  transition: 0.5s;
  color: #0077b5;
}

button svg {
  position: absolute;
  top: 0.45em;
  right: 0.5em;
  margin: 0;
  padding: 0;
  opacity: 0;
  transition: 0.5s;
  height: 1em;
  fill: #fff;
}

button:hover p {
  left: 0.6em;
  color: #fff;
}

button:hover svg {
  opacity: 1;
}

button:hover {
  background-color: #0077b5;
}

/*  */

.btn {
  margin-left: 40px;
}
.btn-p {
  margin-left: 7px;
}


</style>