
import vue from 'vue'
import App from './App.vue'
import store from './store'
import qs from 'qs'
import axios  from 'axios'
// import 'github-markdown-css/github-markdown.css'

   
// 引入mock
import '@/mock/mockSever'
// 引入vue-router
import VueRouter from 'vue-router'
// 引入路由器
import router from './router/index'
import ElementUI from 'element-ui';
import VueHighlightJS from 'vue-highlightjs'
import 'highlight.js/styles/atom-one-dark.css'
vue.use(VueHighlightJS)

   
vue.use(ElementUI);
vue.prototype.$qs = qs
vue.prototype.$axios = axios;
import 'element-ui/lib/theme-chalk/index.css';








// 应用插件
vue.use(VueRouter)

// 重写push||replace
// 第一个参数：告诉原来的push方法，往哪跳转
// 第二个参数，成功的回调
// 第三个参数，失败的回调
let originReplace = VueRouter.prototype.replace
let originPush = VueRouter.prototype.push
VueRouter.prototype.push = function(location,resolve,reject){
    if(resolve && reject){
        originPush.call(this,location,resolve,reject)
    }else{
        originPush.call(this,location,() =>{},() =>{})
    }
}
// 重写replace
VueRouter.prototype.replace = function(location,resolve,reject){
    if(resolve &&reject){
        originReplace.call(this,location,resolve,reject)
    }else{
        originReplace.call(this,location,() =>{},() =>{})
    }
}
// 




new vue({
    el:'#root',
    render:h => h(App),
    router:router,
    store,
    beforeCreate(){
        // 全局事件总线
        vue.prototype.$bus = this
    },

})
