<template>
  <!-- 公共组件分类标签 -->
  <div class="box">
    <!-- <div class="tags">
      <ul class="tags-ul">
        <li @click="gojavascriptblog()">
          <p>#</p>
          <a>javascript</a>
        </li>
        <li @click="gomoblieblog()">
          <p>#</p>
          <a>移动端</a>
        </li>
        <li @click="govueblog()">
          <p>#</p>
          <a>vue</a>
        </li>
        <li @click="gonodeblog()">
          <p>#</p>
          <a>node</a>
        </li>
        <li>
          <p>#</p>
          <a>其他分类</a>
        </li>
      </ul>
    </div> -->
    <div class="right">
      <ul class="ul">
        <li
          v-for="(item, index) in Notejs"
          :key="index"
          class="li"
          @click="gomoreblogtext(item)"
        >
          <h2 class="h2">{{ item.js_title }}</h2>

          <div class="brief">
            {{ item.js_briefly }}
          </div>
          <div class="botton">
            <img
              src="./images/head.jpg"
              alt=""
              height="30px"
              width="30px"
              class="img1"
            />
            <h3>Mu</h3>
            <img
              src="./images/time.png"
              alt=""
              class="img2"
              height="20px"
              width="20px"
            />
            <h3>{{ item.create_time }}</h3>
            <img
              src="./images/分类 (4).png"
              alt=""
              class="img3"
              height="20px"
              width="20px"
            />
            <h3>{{ item.type_name }}</h3>
            <div class="textview">
              <h3>{{ item.views }}</h3>
            </div>
            <img
              src="./images/眼睛 (1).png"
              alt=""
              width="25px"
              height="25px"
              class="view"
            />
            <div class="textlike">
              <h3 class="likenum">{{ item.like }}</h3>
            </div>

            <img
              src="./images/点赞.png"
              alt=""
              width="20px"
              height="20px   
  "
              class="like"
            />
          </div>
        </li>
      </ul>
      <div class="el-page">
        <el-pagination
        background
        layout="prev, pager, next"
        :total="totalpages * 10"
        @current-change="handleCurrentChange"
        class="page"
        :pager-count="5"
        @click.native="getnum(categorynum)"
      >
      </el-pagination>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BlogCategory",
  data() {
    return {
      notejs: {},
      currentPage: 1,
      // 当前 类型博客 的参数
      categorynum: this.$route.params.id,
    };
  },
  mounted() {
    // console.log(this.$route.params.id, "这是传过来博客分类的id");
    var categoryid = this.$route.params.id;
    var params1 = new URLSearchParams();
    params1.append("page", 1);
    params1.append("categoryid", categoryid);
    this.$store.dispatch("getjsbycategoryid", params1);
  },
  computed: {
    ...mapState({
      Notejs: (state) => state.NoteJs.NoteJs.data,
      // 多少页
      ...mapState({
        totalpages: (state) => state.NoteJs.NoteJs.totalPages,
      }),
      // 一共多少条数据
      ...mapState({
        allcounts: (state) => state.NoteJs.NoteJs.allcounts,
      }),
    }),
  },
  methods: {
    // 检测当前是多少页
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
    },
    // 根据博客 分类id 获取博客数据的 方法
    GetCategoryById(id) {
      var params1 = new URLSearchParams();
      params1.append("page", 1);
      params1.append("categoryid", id);
      this.$store.dispatch("getjsbycategoryid", params1);
    },
    // 获取 javasript 分类博客的接口
    gojavascriptblog() {
      this.GetCategoryById(0);
      this.categorynum = 0;
    },
    // 获取 移动端 分类博客的接口
    gomoblieblog() {
      this.GetCategoryById(1);
      this.categorynum = 1;
    },
    govueblog() {
      this.GetCategoryById(2);
      this.categorynum = 2;
    },
    gonodeblog() {
      this.GetCategoryById(3);
      this.categorynum = 3;
    },
    // 分页数据
    getnum(num) {
      var params1 = new URLSearchParams();
      params1.append("page", this.currentPage);
      params1.append("categoryid", num);
      // console.log(this.currentPage, "这是多少页");
      // console.log(num, "这是代表博客类型的id");
      this.$store.dispatch("getjsbycategoryid", params1);
      // 平滑滚动到顶部
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    // 点击跳转到具体博客文档的方法 需要传递id参数
    gomoreblogtext(item) {
      // console.log(item.id, "这是要传的ID");
      // this.$router.push({path:'/moreblogtext'})
      this.$router.push({
        // 此传参方法数据不会丢失
        path: "/moreblogtext/" + item.id,
      });
    },
  },
};
</script>

<style scoped>
.box{
  display: flex;
  margin: 0 auto;
  margin-top: 40px;
}


.page /deep/ .btn-prev {

/* border-radius: 12px !important; */
display: none;
background-color: #ffe8e7 !important;
}

.page /deep/ .btn-next {
  /* display: none; */
  /* display: none !important; */
  /* border-radius: 12px !important; */
  /* background-image: linear-gradient(to top, #ffcecb 0%, #c7e0eb 100%)!important; */
  background-color: #ffe8e7 !important;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  overflow: hidden;
}

.page /deep/ .number {
  /* 修改 li 标签的样式 */
  /* background-color: #f3e5d2 !important; */
  background-image: linear-gradient(to top, hsl(0, 90%, 92%) 0%, #effafd 100%);
  border-radius: 50% !important ;
  line-height: 40px;
  width: 40px;
  height: 40px;
}
/* 数据 */

.right {
  /* width: 800px; */
  /* height: 700px; */
  /* background-color: aqua; */
  /* margin-left: 420px; */
  margin: 0 auto;
  width: 70%;
}
.ul {
  
}
.ul li {
  height: 300px;
  box-shadow: 0 2px 4px rgba(5, 138, 255, 0.12), 0 0 6px rgba(6, 144, 224, 0.04);
  width: 100%;
  background-color: #ffffffcc;
  /* background-color: rgb(172, 195, 215); */
  /* background-image: url("./images/gift-gad2c18e52_1280.jpg"); */
  background-size: 60%;
  border-radius: 3px;
  margin-bottom: 25px;
}
.h2 {
  display: inline-block;
  line-height: 50px;
  margin-left: 50px;
  color: rgb(241, 194, 194);
}

.botton {
  /* position: absolute; */
  margin-top: 45px;
  margin-left: 47px;
}
.img1 {
  float: left;
  border-radius: 50%;
}
.botton h3 {
  float: left;
  margin-left: 10px;
  line-height: 30px;
  font-family: "微软雅黑";
  font-weight: 500;
  font-size: 10px;
}
.li:hover {

}
.el-page{
  margin-bottom: 100px;
  display: flex;
}
.page{
  margin: 0 auto;
  margin-bottom: 20px;
}
.img2 {
  float: left;
  margin-top: 5px;
  margin-left: 20px;
}
.img3 {
  float: left;
  margin-left: 20px;
  margin-top: 5px;
}

.ul li {
  transition: all 0.2s;
  cursor: pointer;
}
.ul li:hover {
  /* transform: scale(1.01); */
  transform: translateY(-10px);
  /* color: rgba(247, 66, 0, 0.999); */
}
.ul-text {
  float: left;
  margin-left: 100px;
}
.allcounts {
  width: 30px;
  height: 30px;
  background-image: linear-gradient(
    to top,
    #fad0c4 0%,
    #fad0c4 1%,
    #ffd1ff 100%
  );
  float: right;
  margin-top: 12px;
  border-radius: 50%;
  /* border: 0.5px solid rgb(128, 126, 126); */
  margin-right: 50px;
  line-height: 28px;
  text-align: center;
  font-size: larger;
  /* color: #000000; */
}


.like {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
}
.view{
  float: right;
  /* margin-right: 5px; */
  margin-top: 4px;
}
.textview{
  float: right;
  margin-right: 40px;
  color: #fad0c4;
  margin-top: 2px;
  /* line-height: 100px; */
}
.textlike {
  float: right;
  margin-right: 35px;
  color: #fad0c4;
  margin-top: 2px;
  font-size: medium;
}
/* 简介 */
.brief {
  margin-top: 30px;
  width: 500px;
  height: 130px;
  /* background-color: #fad0c4; */
  /* font-family: "楷体"; */
  font-size: larger;
  margin-left: 50px;
  /* 推荐二*/
  font-family: Helvetica, Tahoma, Arial, "PingFang SC", "Hiragino Sans GB",
    "Heiti SC", STXihei, "Microsoft YaHei", SimHei, "WenQuanYi Micro Hei";
  color: #7d93b2;
  border-bottom: 0.3px inset rgb(188, 184, 184);
}
/*  */
:deep(number.active) {
  background-color: #ffd1ff;
}
@media screen and (max-width:700px) {
  .right{
    width: 90%;
  }
  .brief{
    width: 85%;
  }
  .view{
    display: none;

  }
  .textview{
    display: none;
  }
  .like{
    display: none;
  }
  .likenum{
    display: none;
  }
}
</style>    