<template>
  <div class="box1">
    <div class="category">
      <div style="margin:0 auto">
        <img
          src="./images/分类查看.png"
          alt=""
          style="float: left; margin-right: 20px"
        />
        <h1 style="float: left; line-height: 50px">{{ categoryname }}</h1>
      </div>
    </div>
    <transition
      appear=""
      name="animate__animated animate__bounce"
      enter-active-class="animate__fadeInDown"
    >
      <blogcategory></blogcategory>
    </transition>
  </div>
</template>

<script>
import blogcategory from "@/components/blogcategory/index.vue";
export default {
  name: "NoteType",
  components: { blogcategory },
  mounted() {
    // console.log(this.$route.query.categoryname, "这是分类名字");
  },
  computed: {
    categoryname() {
      return this.$route.query.categoryname;
    },
  },
};
</script>

<style scoped>
.box1 {
  padding-top: 150px;
  /* height: 1700px; */
  /* width: 2000px; */
  /* height: 1500px; */
/* background-color: aqua; */
  /* background-image: linear-gradient(to top, #d0e3eb 0%, #deb1d7 100%); */

}
.category {
  width: 70%;
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  /* background-color: #ace0f9; */
  margin: 0 auto;
  /* margin-bottom: 100px; */
  
  /* border-bottom: 0.3px inset rgb(179, 199, 205); */
}
</style>