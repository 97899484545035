// axious二次封装
import axios from "axios";
// 利用axios对象的方法create创建一个axios实例

// 引入进度条-nprofress
// 引入进度条的样式
import "nprogress/nprogress.css"
import nprogress from 'nprogress'

// 在当前模块中引入store
// import store from '@/store'
const requests = axios.create({
    // 配置对象
    baseURL: "/api",
    // 代表请求超时的时间
    timeout: 5000,
});
// 请求拦截器，发请求之前做一些事情
requests.interceptors.request.use((config) => {
    // config:配置对象，里面有一个header请求头
        
    // 进度条开始动
    //  如果

    // if (store.state.detail.uuid_token
    // ) {
    //     // 给请求头添加字段(userTempid)，跟后端对接
    //     config.headers.userTempId =store.state.detail.uuid_token
    // }
    nprogress.start()
    return config
});
// 响应拦截器
   

requests.interceptors.response.use((res) => {
    nprogress.done()
    
    // 成功的回调数据
    return res.data
}, (error) => {
    return Promise.reject(new error('faile'))
})




export default requests