// 根据id获取详细博客文档的仓库
import { getblogtextbyid } from '@/api'
import Vue from 'vue'
// 引入vuex
import Vuex from 'vuex'

Vue.use(Vuex)

const actions = {
    // 将账号密码传给mutations
    
    async getblogbyid({ commit },params) {
        const result = await getblogtextbyid (params)
    
       

        if (result.code == 200) {
          
            commit("Getblogbyid", result.data)
            // console.log(result.data,"这是仓库上的数据*****");
            // console.log('---------------------------');
        }

    },

    
}
const mutations = {
    Getblogbyid(state,value){
        state.blogtext= value 
        console.log(state.blogtext,"这是mutations");
        console.log(typeof(state.blogtext),"这是mutation的类型");
     
    },
 
}
const state = {

    blogtext:{}
   
}

export default {
    // namespaced: true,  //关闭
    actions,
    mutations,
    state,

 
}