// 此模块为vuex模块化配置文件
import Vuex from 'vuex'
import vue from 'vue'
vue.use(Vuex)

// 模块化开发 需要 引入小仓库 

import CssWebSite from './CssWebSite'
import VgWebSite from './VgImgWebSite'
import UiWebsite from './UiWebsite'
import JsWebSite from './JsWebSite'
import VueWebSite from './VueWebSite'
import NoteJs from './NoteJS'
import BlogTextById from './BlogTextById'
import BlogCategoryId from './BlogCategoryId'
import BlogCommentById from './BlogCommentById'
import BlogOtherById from './BlogOtherById'
export default new Vuex.Store({
    //实现Vuex模块发开发储存数据
    modules:{
        CssWebSite,
        VgWebSite ,
        UiWebsite,
        JsWebSite,
        VueWebSite,
        NoteJs,
        BlogTextById,
        BlogCategoryId,
        BlogCommentById,
        BlogOtherById
        
    } 

})