// 该文件用于创建Vuex中最为核心的store
import { reqGetWebCss } from '@/api'
import Vue from 'vue'
// 引入vuex
import Vuex from 'vuex'

Vue.use(Vuex)

const actions = {
    // 将账号密码传给mutations
    async getcsswebsites({ commit }) {
        const result = await reqGetWebCss()
    


        if (result.code == 200) {
          
            commit("Getcsswebsites", result.data)
        }

    },

    
}
const mutations = {
    Getcsswebsites(state,value){
        state.CssWebSites= value
     
    },
 
}
const state = {
    CssWebSites:[]
   
}

export default {
    // namespaced: true,  //关闭
    actions,
    mutations,
    state,
 
}