// 先引入mockjs模块
// JSON数据格式文件默认暴露
import Mock from 'mockjs'

import collection from './collection.json'
import WebCss from './WebCss.json'
import WebVgImg from './WebVgImg.json'
import WebUi from './WebUi.json'
import WebJs from './WebJavaScript.json'
import WebVue from './WebVue.json'
// mock方法 第一个参数（请求地址） 第二个参数  ：（请求数据）

Mock.mock("/mock/collection",{code:200,data:collection})
// 常用网站CSS
Mock.mock("/mock/WebCss",{code:200,data:WebCss})
// 常用网站矢量图
Mock.mock("/mock/WebVgImg",{code:200,data:WebVgImg})
// 常用网站UI库
Mock.mock("/mock/WebUi",{code:200,data:WebUi})
// 常用网站javascript库
Mock.mock("/mock/WebJs",{code:200,data:WebJs})
// 常用网站vue库
Mock.mock("/mock/WebVue",{code:200,data:WebVue})