<template>
  <transition
    appear=""
    name="animate__animated animate__bounce"
    enter-active-class="animate__fadeInDown"
  >
    <div class="header" ref="header">
      <img
        src="./images/LOGO2.png"
        alt=""
        width="150px"
        height="75px"
        class="header-logo"
        @click="goheader()"
      />
      <div class="header-weather">
        <div id="he-plugin-simple"></div>
      </div>
      <div class="classify">
 
        <div class="categories">
          <div
            class="category"
            id="all"
            v-for="(item, index) in catevalue"
            @click="gocategorys(item.id)"
            :class="{ cateactive: isshow === item.id }"
            :key="index"
          >
            <a>{{ item.value }}</a>
          </div>
    
        </div>
      </div>
      <div class="menu">
        <label for="burger" class="burger" ref="burger">
          <input id="burger" type="checkbox" @click="showmenu" />
          <span></span>
          <span></span>
          <span></span>
        </label>
      </div>
      <div class="menu-text" v-if="menuisshow">
        <ul class="menu-ul">
          <li @click="goheader1()">主页</li>
          <li @click="gowebsite1()">常用网站</li>
          <li @click="goblogs1()">Note</li>
          <li @click="gomore()">更多~</li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "WebHeader",
  mounted() {

    // 让首页的分类栏有样式
    this.isshow = 1;
    window.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    // 触发事件，改变点击样式
    this.$bus.$on('ChangeWebsite', eventData => {
      // 处理事件数据
      this.isshow = eventData
});
this.$bus.$on('ChangeNote', eventData => {
      // 处理事件数据
      this.isshow = eventData
});
  },
  data() {
    return {
      activeName: "first",
      menuisshow: false,
      // 控制header 透明度 跟随滚动变化
      // 控制分类的展示状态
      isshow: null,
      inAnimation: false,
      // v-for需要生成分类的数据
      catevalue: [
        { id: 1, value: "主页" },
        { id: 2, value: "常用网站" },
        { id: 3, value: "Blogs" },
        { id: 4, value: "More" },
      ],
    };
  },
  methods: {
    handleScroll() {
      // console.log(document.documentElement.scrollTop, "这是top");
      if (document.documentElement.scrollTop > 100) {
        this.inAnimation = true;

        // console.log(this.$refs.header);
        this.$refs.header.style.background = "#FFFFFFCC";
      } else {
        this.$refs.header.style.background = "";
      }
    },
    // 移动端手机菜单的显示与隐藏
    showmenu() {
      this.menuisshow = !this.menuisshow;
      // console.log(this.menuisshow);
      // this.menuisshow = !this.menuisshow
    },
    handleClick(tab, event) {
      // console.log(tab, event);

      if (this.activeName == "first") this.$router.push({ name: "mainpage" });

      if (this.activeName == "second") this.$router.push({ name: "website" });

      if (this.activeName == "third") this.$router.push({ name: "note" });
    },

    // 通过分类跳转到各个路由的方法
    gocategorys(id) {
      this.isshow = id;
      // console.log(id, "这是id");
      // console.log(this.isshow, "这是isshow");
      if (id == 1) {
        this.$router.push({ name: "mainpage" });
      }
      if (id == 2) {
        this.$router.push({ name: "websitelike" });
      }
      if (id == 3) {
        this.$router.push({ name: "note" });
      }
      if(id==4) {
        this.$router.push({ name: "more" });
      }
    },
    gowebsite() {
      // console.log(111);
      this.$router.push({ name: "websitelike" });
      // this.menuisshow = false
      // this.$refs.burger.click()
    },
    // 点击LOGO跳转首页
    goheader() {
      this.isshow=1
      this.$router.push({ name: "mainpage" });
      // this.menuisshow = false
      // this.$refs.burger.click()
    },
    goblogs() {
      this.$router.push({ name: "note" });
      // this.menuisshow = false
      
      // this.$refs.burger.click()

    },
    gomore(){
      this.$router.push({ name: "more" });
      this.$refs.burger.click()
      this.menuisshow = false
    },
    gowebsite1() {
      // console.log(111);
      this.$router.push({ name: "websitelike" });
      this.$refs.burger.click()
      this.menuisshow = false
      // console.log(this.menuisshow,"这是现不现实");

    },
    // 点击LOGO跳转首页
    goheader1() {
      this.isshow=1
      this.$refs.burger.click()
      this.$router.push({ name: "mainpage" });
      this.menuisshow = false
      // console.log(this.menuisshow,"这是现不现实");
 
    },
    goblogs1() {
      this.$router.push({ name: "note" });
      this.$refs.burger.click()
      this.menuisshow = false
      // console.log(this.menuisshow,"这是现不现实");


    },

    // 将按钮的状态存放在本地的方法
    // btn指的是哪个按钮的
      localbtn(btn){

      }
  },
};
</script>

<style scoped>
/*渐变动画 */
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");

*{
  font-family: "Oswald", sans-serif;
}
.he-plugin-simple4 {
  z-index: 100;
}
* {
  padding: 0;
  margin: 0;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.header {
  /* display: flex; */
  z-index: 1000;
  position: fixed;
  height: 70px;
  width: 100%;
  display:  flex;
  justify-items: center;
  /* border-radius: 12px; */
  /* background: yellow; */
  backdrop-filter: blur(10px);
  /* background-color: #84848433; */
  animation: showMsg 2s;

}
.header-logo {
  margin-left: 10%;
  float: left;
}
.header-weather {
  width: 100px;
  margin-left: 30%;
  float: left;
  margin-top: 25px;
}
.changelight {
  float: left;
  margin-top: 20px;
}
.classify {
  /* width: 400px; */
  height: 60px;
  /* background-color: aqua; */
  float: left;
  margin-left: 200px;
  margin-top: 20px;
}
.categories {
  display: flex;
  justify-content: center;
  align-items: center;
}

.category {
  margin: 10px;
  padding: 5px 10px;
  border-radius: 12px;
  /* background-color: #eee; */
  text-align: center;
  transition: all 0.3s ease-in-out;
  /* width: 70px; */
  height: 20px;

  font-size: medium;
  font-family: Helvetica, Tahoma, Arial, "PingFang SC", "Hiragino Sans GB",
    "Heiti SC", STXihei, "Microsoft YaHei", SimHei, "WenQuanYi Micro Hei";
  color: #a1ecf3;
}
.cateactive {
  color: #ffffff;
  background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);

   
}

.category:hover {
  background-color: #ffebf3;
  color: #fff;
  cursor: pointer;
}

.category a {
  color: inherit;
  text-decoration: none;
}

.classify li {
  cursor: pointer;
  display: inline-block;
  margin-left: 30px;
  font-size: large;
  font-weight: 500;
  line-height: 50px;
  color: rgb(0, 0, 0);
  /* width: 40px; */
}
.classify li:hover {
  border-bottom: 4px solid rgb(20, 185, 231);
}
.classify .el {
  border: none;
}
.menu {
  display: none;
  position: absolute;
  left: 80%;
  top: 30px;
}
.menu-text {
  position: absolute;
  width: 120px;
  /* height: 200px; */
  background-color: rgb(249, 253, 255);
  /* border-radius: 8px; */
  /* left: 270px; */
  top: 70px;
}
.menu-ul li {
  line-height:50px;
  width: 130px;
  /* height: 50px; */
  /* background-color: rgb(198, 123, 43); */
  font-size: larger;
  border-bottom: 2px #ececec solid;
  /* text-align: center; */
  /* background-color: #c7bdbd; */

  /* margin-left: 300px; */
  margin-left: 40px;
  margin-top: 5px;
  /* font-weight: 600; */
}
/*  */
/* The switch - the box around the slider */
.burger {
  position: absolute;
  width: 40px;
  height: 30px;
  background: transparent;
  cursor: pointer;
}

.burger input {
  display: none;
}

.burger span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: rgb(182, 139, 128);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.burger span:nth-of-type(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

.burger span:nth-of-type(2) {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

.burger span:nth-of-type(3) {
  top: 100%;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.burger input:checked ~ span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0px;
  left: 5px;
}

.burger input:checked ~ span:nth-of-type(2) {
  width: 0%;
  opacity: 0;
}

.burger input:checked ~ span:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 28px;
  left: 5px;
}
/*  */
@media screen and (max-width: 1360px) {
  .header-weather {
    /* background-color: aquamarine; */
    margin-left: 100px;
  }
}

@media screen and (max-width: 1100px) {
  .header-weather {
    position: absolute;
    left: 100px;
    width: 150px !important;
    /* background-color: #ace0f9; */
    border-radius: 20px;
    overflow: hidden !important;
    margin-left: 30px;
  }
  .classify {
    margin-left: 40px;
  }
}
@media screen and (max-width: 1000px) {
  .header-weather /deep/ svg{
  display: none !important;
}
  .header{
    width: 100%;
  }
  .classify {
    margin-left: 10px;
    display: none;
  }
  .menu {
    display: block;
  }
  .menu-text{
    width: 100%;
  }
  .menu-ul li{
    width: 100%;
  }
  .header-logo {
    margin-top: 20px;
    margin-left: 10px;
    width: 100px;
    height: 50px;
  }
}

</style>