// 获取分页数据的仓库
import { reqTest2 } from '@/api'
import{getblogbycategoryid} from '@/api'
import Vue from 'vue'
// 引入vuex
import Vuex from 'vuex'

Vue.use(Vuex)

const actions = {
// 根据分页获取所有类型博客数据
    async getjavascript({ commit },params) {
        const result = await reqTest2(params)
  
        if (result.code == 200) {
          
            console.log(result,"这是所有类型博客的分页数据");
            commit("Test", result)
        }

    },
// 根据博客类型和分页获取js分类博客的数据

      async getjsbycategoryid({ commit },params) {
        const result = await getblogbycategoryid(params)
  
        if (result.code == 200) {
          
            console.log(result,"这是分类博客的数据");
            commit("Getjsbycategoryid", result)
        }

    },

    }
  
    

const mutations = {
    
    Test(state,value){
        state.NoteJs= value
     
    },
    Getjsbycategoryid(state,value){
        state.NoteJs = value
    }
   
}
const state = {
    // 这是分页数据
    NoteJs:{}
   
}

export default {
    // namespaced: true,  //关闭
    actions,
    mutations,
    state,
 
}