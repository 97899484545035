
<template>
  <div class="box22">
    <WebHeader></WebHeader>
    <!-- 路由组件出口的地方 -->

    <router-view></router-view>
    <Friends></Friends>
      <floor></floor>
  </div>
</template>

<script>
import WebHeader from "./components/WebHeader";
import Friends from "./components/friends"
import floor from "./components/floor"
export default {
  name: "App",
  mounted() {

 
  },
  // 判断 当页面刷新后如果路由路径不再主页，则返回主页
  created() {
      if (this.$router.path !== "/mainpage")
        this.$router.replace("mainpage")
    },
  components: { WebHeader,Friends,floor},
  methods: {},
};
</script>

<style >
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
:focus {
  outline: none;
}

*{
  font-family: "Oswald", sans-serif;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
}


.box22{

  /* background-color: brown; */

}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
/* 此处的 div 可以依照情况替换成对应的元素名称 */

</style>

